import React, { useContext, useState } from 'react'

//Context
import { RouterContext } from '../../../Context/RouterContext';

import logo from '../../../Assets/Grupo-NIN_logo.png';
import { removeUserSession } from '../../../helpers/admin/adminHelper';

//MUI
import { AppBar, Box, Button, Fab, Fade, ListItemIcon, Menu, MenuItem, Toolbar, useMediaQuery, useScrollTrigger } from '@mui/material';
import { styled } from '@mui/material/styles';
import Logout from '@mui/icons-material/Logout';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#000',
    textTransform: 'none'
}));

//Scroll to top
function ScrollTop(props) {
    const { win } = props;

    const trigger = useScrollTrigger({
        target: win ? window() : undefined,
        disableHysteresis: true,
        threshold: 800,
    });

    const handleClick = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <Fab size="small">
                    <KeyboardArrowUpIcon />
                </Fab>
            </Box>
        </Fade>
    );
}

const HeaderAdmin = (props) => {
    const { item, setItem } = useContext(RouterContext);
    const { username } = item;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // cerrar sesión
    const userLogout = () => {
        setAnchorEl(null);
        removeUserSession(setItem);
    };

    const matches = useMediaQuery('(max-width: 991.5px)')

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position='sticky' sx={{ bgcolor: '#fff', borderBottom: '1px solid #E5E7E9' }} elevation={matches ? 0 : 3}>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <img src={logo} alt='Grupo_NIN' width='50' />
                    </Box>
                    <ColorButton onClick={handleOpenMenu} endIcon={<ArrowDropDownIcon />}>¡Hola {username}!</ColorButton>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleCloseMenu}
                        onClick={handleCloseMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 9,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={userLogout}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Cerrar sesión
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

            <ScrollTop {...props} />

        </Box>
    )
}

export default HeaderAdmin