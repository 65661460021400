import React from 'react';

import { Box, useMediaQuery } from '@mui/material';

//rsuite (Only dateRangePicker)
import DateRangePicker from 'rsuite/DateRangePicker';
import { CustomProvider } from 'rsuite';
import esES from 'rsuite/locales/es_ES';

// (Optional) Import component styles. If you are using Less, import the `index.less` file. 
import 'rsuite/DateRangePicker/styles/index.css';

const DateFilter = ({ filterDate, setFilterDate }) => {

    const matches = useMediaQuery('(max-width: 500px)')

    return (
        <Box>
            <CustomProvider locale={esES}>
                <DateRangePicker size="lg"
                    value={filterDate}
                    onChange={setFilterDate}
                    cleanable={false}
                    placement='auto'
                    showOneCalendar={matches ? true : false}
                />
            </CustomProvider>
        </Box>
    );
};

export default DateFilter;