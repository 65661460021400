import React from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

//Constants
import { SalesPerformanceOptions } from '../../../constants/graph_constants';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

export const data = {
    labels,
    datasets: [
        {
            label: '2021',
            data: [10000, 11000, 9000, 15000, 16000, 9600, 20000, 22000, 18000, 17000, 14000, 10000],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: '2022',
            data: [7000, 9000, 6000, 10000, 8000, 7600, 12000, 13000, 9500, 3000, 14000, 12000],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
            label: '2023',
            data: [9463, 13561, 9000, 7430, 12391, 8690, 10000, 16000, 6500, 7000, 11000, 8300],
            borderColor: 'rgb(187, 143, 206)',
            backgroundColor: 'rgba(187, 143, 206, 0.5)',
        },
        {
            label: '2024',
            data: [13000, 15000, 12000, 15000, 18000, 10600, 24000, 19000, 19000, 21000, 15400, 11000],
            borderColor: 'rgb(34, 153, 84)',
            backgroundColor: 'rgba(34, 153, 84, 0.5)',
        },
    ],
};

const SalesPerformance = () => {
    return (
        <div style={{ height: '400px', width: '100%' }}>
            <Line options={SalesPerformanceOptions} data={data} />
        </div>
    )
}

export default SalesPerformance