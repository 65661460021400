import { createContext, useContext, useEffect, useState } from "react";
import { DashboardContext } from "./DashboardContext";
import { salesData } from "../actions/data";

export const RouterContext = createContext();
const { Provider } = RouterContext;

const RouterContextProvider = ({ children }) => {
    const { setData } = useContext(DashboardContext);

    const [item, setItem] = useState({
        email: 'it@nefropolis.com',
        username: 'Development',
        loadingSession: true
    });
    const { email } = item;

    useEffect(() => {
        if (email !== '')
            salesData(setData);
    }, [email]);

    return (
        <Provider value={{ item, setItem }}>
            { children }
        </Provider>
    );
};

export default RouterContextProvider;