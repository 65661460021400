import React from 'react'

//MUI
import { Box, Button, Drawer, IconButton, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

//Components
import Filters from '../Filters/Filters';

const ClearFilterBtn = styled(Button)(({ theme }) => ({
    color: '#6C7E97',
    textTransform: 'none',
    backgroundColor: 'none',
}));


const SidebarAdmin = ({
    businessNameDisplay, setBusinessNameDisplay,
    expandedData, setExpandedData,
    mobileOpen, setMobileOpen,
    filterDate, setFilterDate,
    dataForDisplay, setDataForDisplay,
    expanded, setExpanded,
    selectedCategory, setSelectedCategory, INITIAL_DATE, checkedSS, setCheckedSS
}) => {

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const matches = useMediaQuery('(max-width: 375px)')

    return (
        <Drawer
            variant="temporary"
            anchor={'right'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            PaperProps={{
                sx: { width: !matches ? '370px' : '100%' },
            }}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', bgcolor: '#F8F9F9' }}>
                <ClearFilterBtn size='small' sx={{ my: 'auto' }}>Limpiar filtros</ClearFilterBtn>
                <IconButton size="small" sx={{ my: 'auto' }} onClick={handleDrawerToggle}>
                    <CloseIcon fontSize='inherit' />
                </IconButton>
            </Box>
            <Filters
                businessNameDisplay={businessNameDisplay}
                setBusinessNameDisplay={setBusinessNameDisplay}
                expandedData={expandedData}
                setExpandedData={setExpandedData}
                filterDate={filterDate}
                setFilterDate={setFilterDate}
                INITIAL_DATE={INITIAL_DATE}
                dataForDisplay={dataForDisplay}
                setDataForDisplay={setDataForDisplay}
                expanded={expanded}
                setExpanded={setExpanded}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                checkedSS={checkedSS}
                setCheckedSS={setCheckedSS}
            />
        </Drawer>
    )
}

export default SidebarAdmin