import React from 'react';

import { Top10ProductsOptions } from '../../../constants/graph_constants';

//React charts
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
    labels: ['Producto 1', 'Producto 2', 'Producto 3', 'Producto 4', 'Producto 5', 'Producto 6', 'Producto 7', 'Producto 8', 'Producto 9', 'Producto 10'],
    datasets: [
        {
            label: 'Piezas vendidas',
            data: [60, 58, 49, 42, 40, 35, 26, 22, 21, 18],
            backgroundColor: [
                'rgba(240, 128, 128, 0.2)',
                'rgba(187, 143, 206, 0.2)',
                'rgba(133, 193, 233, 0.2)',
                'rgba(205, 92, 92, 0.2)',
                'rgba(130, 224, 170, 0.2)',
                'rgba(246, 221, 204, 0.2)',
                'rgba(84, 153, 199, 0.2)',
                'rgba(247, 220, 111, 0.2)',
                'rgba(220, 118, 51, 0.2)',
                'rgba(52, 73, 94, 0.2)',
            ],
            borderColor: [
                'rgba(240, 128, 128, 0.7)',
                'rgba(187, 143, 206, 0.7)',
                'rgba(133, 193, 233, 0.7)',
                'rgba(205, 92, 92, 0.7)',
                'rgba(130, 224, 170, 0.7)',
                'rgba(246, 221, 204, 0.7)',
                'rgba(84, 153, 199, 0.7)',
                'rgba(247, 220, 111, 0.7)',
                'rgba(220, 118, 51, 0.7)',
                'rgba(52, 73, 94, 0.3)',
            ],
            borderWidth: 1,
        },
    ],
};

const Top10Products = () => {

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <Pie data={data} options={Top10ProductsOptions} />
        </div>
    );
}

export default Top10Products