export const DATASET_GRAPH_ONE = {
    label: 'Cantidad',
    // data: [10000, 11000, 9000, 15000, 16000, 9600, 20000, 22000, 18000, 17000, 14000, 10000],
    data: [],
    borderColor: 'rgb(236, 112, 99)',
    backgroundColor: 'rgba(236, 112, 99, 0.5)'
};

export const DATASET_GRAPH_TWO = {
    label: 'Cantidad en $',
    // data: [10000, 11000, 9000, 15000, 16000, 9600, 20000, 22000, 18000, 17000, 14000, 10000],
    data: [],
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: ["#B03A2E", "#BB8FCE", "#85C1E9", "#CD5C5C", "#82E0AA", "#F6DDCC", "#5499C7", '#F7DC6F', '#DC7633', '#34495E', "#F08080", '#196F3D'],
};

export const DATASET_GRAPH_FOUR = {
    label: 'Total',
    // data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 11, 8],
    data: [],
    backgroundColor: ["#B03A2E", "#BB8FCE", "#85C1E9", "#CD5C5C", "#82E0AA", "#F6DDCC", "#5499C7", '#F7DC6F', '#DC7633', '#34495E', "#F08080", '#196F3D'],
    borderColor: ["#B03A2E", "#BB8FCE", "#85C1E9", "#CD5C5C", "#82E0AA", "#F6DDCC", "#5499C7", '#F7DC6F', '#DC7633', '#34495E', "#F08080", '#196F3D'],
};

//Opciones para la gráfica
export const GRAPH_OPTIONS = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
            onClick: null
        }
    }
};

export const GRAPH_OPTIONS_1 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'right',
            display: true,
            onClick: null
        },
        datasetRadiusBuffer: 25,
        cutoutPercentage: 75
    }
};

export const Top10ProductsOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'left',
            display: true,
            onClick: null
        },
        datasetRadiusBuffer: 25,
        cutoutPercentage: 75
    }
};

export const SumatoriaVentaOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
            onClick: null
        }
    },
};

export const SalesPerformanceOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                padding: 30
            }
        },
        
    },
};