import React from 'react'

import './GraphsStyle.css'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import { SumatoriaVentaOptions } from '../../../constants/graph_constants';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

//Labels gráfica
const labels = ['Guadalajara', 'Iztapalapa', 'León', 'Oaxaca', 'Querétaro', 'Roma', 'Tlalnepantla', 'Tlalpan', 'Tlaxcala', 'Toluca', 'Tuxtla', 'Valle de Aragón'];

const data = {
    labels,
    datasets: [
        {
            label: 'Cantidad',
            data: [10000, 11000, 9000, 15000, 16000, 9600, 20000, 22000, 18000, 17000, 14000, 10000],
            borderColor: 'rgb(127, 179, 213)',
            backgroundColor: 'rgba(127, 179, 213, 0.5)',
        },
    ],
};

const SumatoriaVenta = () => {

    return (
        <div className='SV_Graph' style={{ width: '100%' }}>
            <Line options={SumatoriaVentaOptions} data={data} />
        </div>
    )
}

export default SumatoriaVenta