import React, { useContext } from 'react';
import { Routes, Route } from "react-router-dom";

import { DashboardContext } from '../Context/DashboardContext';

import { PublicRoute } from './PublicRoute';
import { ProtectedRoute } from './ProtectedRoute';

import { AdminRoutes } from './AdminRoutes';

//Components
import Index from '../Components/Index/Index';
import Auth from '../Components/SSO/Auth';

import { Container, CircularProgress } from '@mui/material';

const AppRoute = () => {
  const { data: { firstLoading } } = useContext(DashboardContext);

  if (firstLoading) {
    return (
      <Container maxWidth='xl' sx={{ textAlign: 'center', mt: 5 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/:msg?" element={
          <PublicRoute>
            <Index />
          </PublicRoute>
        } />
        <Route path="/admin/*" element={
          <ProtectedRoute>
            <AdminRoutes />
          </ProtectedRoute>
        } />
        <Route path="/auth_oauth/signin" element={<Auth />} />
      </Routes>
    </>
  );
};

export default AppRoute;