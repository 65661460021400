import React, { useContext, useState } from 'react';

import { DashboardContext } from '../../../Context/DashboardContext';

//MUI
import { FormGroup, FormControlLabel, Checkbox, Button, TextField, InputAdornment, IconButton, OutlinedInput, InputLabel, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const ShowMLButton = styled(Button)(({ theme }) => ({
    color: '#6C7E97',
    textTransform: 'none',
}));

const ProductsFilter = ({
    dataForDisplay, setDataForDisplay,
    expanded, setExpanded
}) => {
    const { data: { products } } = useContext(DashboardContext);

    // seleccionar producto de lista mediante checkbox
    const handleCheckboxChange = (product_id) => {
        setDataForDisplay(e => ([
            ...e.map(
                u => u.product_id === product_id ? ({ ...u, checked: !u.checked }) : u
            )
        ]));
    };

    const [search, setSearch] = useState('')

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const filtered = !search
        ? dataForDisplay
        : dataForDisplay.filter((res) =>
            res.product.toLowerCase().includes(search.toLowerCase())
        );

    const handleResetSearch = () => {
        setSearch('')
    }

    return (
        <>
            <FormControl fullWidth variant="outlined" sx={{ mb: 3 }} size='small'>
                <InputLabel htmlFor="prod_search">Buscar producto</InputLabel>
                <OutlinedInput
                    id="prod_search"
                    value={search}
                    onChange={handleSearch}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleResetSearch}
                                edge="end"
                            >
                                {search.length > 1 ? <CloseIcon /> : null}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Buscar producto"
                />
            </FormControl>
            <FormGroup>
                {
                    filtered.map(({ product_id, product, checked }) => (
                        <FormControlLabel key={product_id}
                            control={
                                <Checkbox
                                    value={product_id}
                                    checked={checked}
                                    onChange={() => handleCheckboxChange(product_id)}
                                />
                            }
                            label={product}
                        />
                    ))
                }
            </FormGroup>
            {/* {
                products.length > 7 &&
                <ShowMLButton onClick={
                    () => setExpanded(!expanded)
                } sx={{ mt: 2 }}>
                    {expanded ? 'Ver menos' : 'Ver más'}
                </ShowMLButton>
            } */}
        </>
    );
};

export default ProductsFilter;