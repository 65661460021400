import React from 'react'

//Chart js
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { GRAPH_OPTIONS } from '../../../constants/graph_constants';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const labels = ['SUC. ROMA', 'SUC. LEON', 'SUC. TUXTLA', 'SUC. VALLE DE ARAGON', 'OAXACA', 'SUC. MONTERREY', 'SUC. TLALPAN', 'SUC. IZTAPALAPA', 'SUC. QUERETARO',
    'SUC. HERMOSILLO', 'SUC. TLALNEPANTLA', 'SUC. TLAXCALA', 'SUC. GUADALAJARA', 'SUC. PUEBLA', 'MeLi', 'AMZ', 'TMO', 'ONL', 'NR', 'SUC. TOLUCA', 'TMD', 'FRA', 'ZNV'];

export const data = {
    labels,
    datasets: [
        {
            data: [12, 15, 90, 30, 40, 20, 13, 25, 22, 10, 14, 20, 21, 51, 59, 9, 33, 28, 19, 45, 76, 48, 88],
            label: 'Porcentaje de ventas',
            backgroundColor: [
                'rgba(240, 128, 128, 0.6)',
                'rgba(187, 143, 206, 0.6)',
                'rgba(133, 193, 233, 0.6)',
                'rgba(205, 92, 92, 0.6)',
                'rgba(130, 224, 170, 0.6)',
                'rgba(246, 221, 204, 0.6)',
                'rgba(84, 153, 199, 0.6)',
                'rgba(247, 220, 111, 0.6)',
                'rgba(220, 118, 51, 0.6)',
                'rgba(52, 73, 94, 0.6)',
                'rgba(20, 90, 50, 0.6)',
                'rgba(110, 44, 0, 0.6)',
                'rgba(74, 35, 90, 0.6)',
            ],
        },
    ],
};

const ParticipaciónVenta = () => {
    return (
        <div style={{ width: '100%', height: '400px', overflow: 'hidden' }}>
            <Bar options={GRAPH_OPTIONS} data={data} style={{ maxWidth: '100%' }} />
        </div>
    )
}

export default ParticipaciónVenta