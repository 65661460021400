import React, { useState, useContext, useEffect } from 'react';
import { startOfDay, endOfDay, subWeeks, compareAsc } from 'date-fns';

import { DashboardContext } from '../../../Context/DashboardContext';

import { salesFilter } from '../../../actions/data';

//Circular progress
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

//CSS
import './IndexAdmin.css';

//MUI
import { Box, Container, Grid, Typography, IconButton, Chip, Button, useMediaQuery } from '@mui/material';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import SyncIcon from '@mui/icons-material/Sync';
import FilterListIcon from '@mui/icons-material/FilterList';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PersonIcon from '@mui/icons-material/Person';

//Components
import Graph1 from '../DashboardComponents/Graph1';
import Graph2 from '../DashboardComponents/Graph2';
import Graph3 from '../DashboardComponents/Graph3';
import Graph4 from '../DashboardComponents/Graph4';
// import Graph5 from '../DashboardComponents/Graph5';
// import Graph6 from '../DashboardComponents/Graph6';
// import Graph7 from '../DashboardComponents/Graph7';
// import Graph8 from '../DashboardComponents/Graph8';
// import CategoriesFilter from '../Filters/CategoriesFilter';
import SidebarAdmin from '../SidebarAdmin/SidebarAdmin';
import Filters from '../Filters/Filters';
import Top10Products from '../DashboardComponents/Top10Products';
import SumatoriaVenta from '../DashboardComponents/SumatoriaVenta';
import SalesPerformance from '../DashboardComponents/SalesPerformance';
import ParticipaciónVenta from '../DashboardComponents/ParticipaciónVenta';

// const TooltipCustomized = styled(({ className, ...props }) => (
//   <Tooltip {...props} arrow classes={{ popper: className }} />
// ))(() => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     maxWidth: 160,
//   },
// }));

const FilterBtn = styled(Button)(({ theme }) => ({
  color: '#6C7E97',
  textTransform: 'none',
  backgroundColor: 'none',
}));

const RefreshBTN = styled(IconButton)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#52BE80',
  '&:hover': {
    backgroundColor: '#339b60',
  },
}));

const today = new Date();
const INITIAL_DATE = [
  startOfDay(new Date('05-20-2024')),
  // subWeeks(startOfDay(today), 1),
  endOfDay(today)
];

const dataPrueba = [
  {
    id: 1,
    name: 'Amazon',
    sales: 456
  },
  {
    id: 2,
    name: 'TMD',
    sales: 402
  },
  {
    id: 3,
    name: 'Puebla',
    sales: 321
  },
  {
    id: 4,
    name: 'SUC. GUADALAJARA',
    sales: 206
  },
  {
    id: 5,
    name: 'SUC. VALLE DE ARAGÓN',
    sales: 202
  }
]

const IndexAdmin = () => {
  const matches = useMediaQuery('(max-width: 991.5px)');
  const { data: { businessName, products, firstLoading, backup }, setData } = useContext(DashboardContext);
  // const tooltipIsOpen = true;
  // const matches = useMediaQuery('(max-width: 500px)');

  // lista de razón social
  const [businessNameDisplay, setBusinessNameDisplay] = useState([]);
  // lista de razón social seleccionados
  const [checkedBusinessName, setCheckedBusinessName] = useState([]);
  // expandir lista de razón social
  const [expandedData, setExpandedData] = useState(false);

  // 
  const [checkedSS, setCheckedSS] = useState([]);

  // lista de todos los productos y checkbox
  const [dataForDisplay, setDataForDisplay] = useState([]);
  // lista de productos seleccionados
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  // expandir lista de todos los productos
  const [expanded, setExpanded] = useState(false);
  // filtro por fecha
  const [filterDate, setFilterDate] = useState(INITIAL_DATE);
  // categoría seleccionada
  const [selectedCategory, setSelectedCategory] = useState('');

  // copiar arreglo de razón social
  useEffect(() => {
    setBusinessNameDisplay(expandedData ? (businessName) : businessName.slice(0, 7));
  }, [businessName, expandedData]);

  // copiar arreglo de productos
  useEffect(() => {
    setDataForDisplay(expanded ? (products) : products);
  }, [products, expanded]);

  // filtra productos seleccionados
  useEffect(() => {
    // if (dataForDisplay.find(e => e.checked)) // para evitar disparar consulta en useEffect de checkedCheckboxes
    setCheckedCheckboxes(
      dataForDisplay.filter(
        e => e.checked
      ).map(
        ({ product_id, product }) => ({ product_id, product })
      )
    );

    setCheckedBusinessName(
      businessNameDisplay.filter(
        e => e.checked
      ).map(
        ({ business_id, business_name }) => ({ business_id, business_name })
      )
    );
  }, [dataForDisplay, businessNameDisplay]);

  useEffect(() => {
    if (!firstLoading) { // se cargó la información por primera vez
      const businessNameFilter = businessNameDisplay.filter(e => e.checked);
      if (
        businessNameFilter.length > 0 || // razón social seleccionada
        compareAsc(INITIAL_DATE[0], filterDate[0]) !== 0 || // cambió fecha inicial
        compareAsc(INITIAL_DATE[1], filterDate[1]) !== 0 || // cambió fecha final
        checkedCheckboxes.length > 0 || // productos seleccionados
        selectedCategory !== '' // categoría seleccionada
      )
        salesFilter( // petición a BD
          businessNameFilter,
          filterDate, checkedCheckboxes, selectedCategory,
          setData
        );
      else
        setData(e => ({ ...e, ...backup }));
    }
  }, [businessNameDisplay, filterDate, checkedCheckboxes, selectedCategory]);

  // limpiar selección de productos
  const handleResetFilters = () => {
    setCheckedCheckboxes([]);
    setDataForDisplay(e => e.map(u => ({ ...u, checked: false })));
    setBusinessNameDisplay(e => e.map(u => ({ ...u, checked: false })));
    setSelectedCategory('');
    setCheckedSS([])
  };

  // deseleccionar producto de lista
  const unselectProduct = product_id => () => {
    setDataForDisplay(
      e => e.map(
        u => u.product_id === product_id ? ({ ...u, checked: false }) : u
      )
    );
    setCheckedCheckboxes(e => e.filter(u => u.product_id !== product_id));
  };

  // deseleccionar producto de lista
  const unselectBusiness = business_id => () => {
    setBusinessNameDisplay(
      e => e.map(
        u => u.business_id === business_id ? ({ ...u, checked: false }) : u
      )
    );
    setCheckedBusinessName(e => e.filter(u => u.business_id !== business_id));
  };

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(true);
  };

  return (
    <>
      <Box className='filters_mobile'>
        <FilterBtn onClick={handleDrawerToggle} endIcon={<FilterListIcon />}>Filtrar</FilterBtn>
      </Box>

      <Container maxWidth='xxxl' sx={{ mb: 10, mt: matches ? 4 : 8 }}>
        <Grid container spacing={4}>
          {/* Filtros */}
          <Grid item xl={2.5} lg={4} md={12} sm={12} xs={12}>
            <Box className='filter'>
              <Box sx={{ mb: checkedCheckboxes.length >= 1 || checkedBusinessName.length >= 1 || selectedCategory.length >= 1 || checkedSS.length >= 1 ? 3 : 0 }}>
                {
                  checkedCheckboxes.length >= 1 || checkedBusinessName.length >= 1 || selectedCategory.length >= 1 || checkedSS.length >= 1
                    ?
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', mb: 2 }}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', my: 'auto' }}>Filtros</Typography>
                        <Button size='small' sx={{ textTransform: 'none', my: 'auto' }} onClick={handleResetFilters}>Limpiar filtros</Button>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '10px' }}>
                        {
                          checkedBusinessName.map(({ business_id, business_name }) => (
                            <Chip key={business_id} label={business_name}
                              size='small' color="primary" variant="outlined"
                              onDelete={unselectBusiness(business_id)}
                            />
                          ))
                        }

                        {
                          checkedCheckboxes.map(({ product_id, product }) => (
                            <Chip key={product_id} label={product}
                              size='small' color="primary" variant="outlined"
                              onDelete={unselectProduct(product_id)}
                            />
                          ))
                        }

                        {
                          selectedCategory.length >= 1
                            ?
                            <Chip label={selectedCategory} size='small' color='primary' variant='outlined' onDelete={() => { setSelectedCategory('') }} />
                            :
                            null
                        }

                        {
                          checkedSS.map((res) => (
                            <Chip key={res.id} label={res.name}
                              size='small' color="primary" variant="outlined"
                              onDelete={() => { alert('Deseleccionar') }}
                            />
                          ))
                        }

                      </Box>
                    </>
                    :
                    null
                }
              </Box>

              <Box className='filters_desktop'>
                <Filters
                  businessNameDisplay={businessNameDisplay}
                  setBusinessNameDisplay={setBusinessNameDisplay}
                  expandedData={expandedData}
                  setExpandedData={setExpandedData}
                  filterDate={filterDate}
                  setFilterDate={setFilterDate}
                  INITIAL_DATE={INITIAL_DATE}
                  dataForDisplay={dataForDisplay}
                  setDataForDisplay={setDataForDisplay}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  checkedSS={checkedSS}
                  setCheckedSS={setCheckedSS}
                />
              </Box>

            </Box>
          </Grid>

          {/* Contenido del dashboard */}
          <Grid item xl={9.5} lg={8} md={12} sm={12} xs={12}>

            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ my: 'auto' }}>
                <Typography variant='body1' gutterBottom sx={{ fontWeight: '600' }} >Fecha establecida:</Typography>
                <Chip
                  label={`${filterDate.toLocaleString().split(',')[0]} - ${filterDate.toLocaleString().split(',')[2]}`}
                  size='small'
                  color="primary"
                  variant='outlined'
                />
              </Box>
              <RefreshBTN size="large" sx={{ my: 'auto' }}>
                <SyncIcon fontSize='Inherit' />
              </RefreshBTN>
            </Box>

            <Grid container spacing={4}>

              {/* Objetivo ingresos anual */}
              <Grid item xxl={3} xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                <Box className='graph_card' sx={{ width: '100%' }}>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Objetivo ingresos anual
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3, textAlign: 'center' }}>
                    <AttachMoneyIcon fontSize='large' sx={{ mb: 3, color: '#52BE80', fontSize: '45px' }} />
                    <Typography variant='h5' sx={{ fontWeight: '500' }}>
                      $999,999,999.99
                    </Typography>
                    <Typography variant='h5' sx={{ fontWeight: '500' }}>MXN</Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Objetivo anual de abrazos brindados */}
              <Grid item xxl={3} xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                <Box className='graph_card' sx={{ width: '100%' }}>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Objetivo anual de abrazos brindados
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3, textAlign: 'center ' }}>
                    <HandshakeIcon fontSize='large' sx={{ mb: 3, color: '#3498DB', fontSize: '45px' }} />
                    <Typography variant='h5' sx={{ fontWeight: '500' }}>
                      999,999,999.99
                    </Typography>
                    <Typography variant='h5' sx={{ fontWeight: '500' }}>Abrazos</Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Progreso anual de ingresos */}
              <Grid item xxl={3} xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                <Box className='graph_card' sx={{ width: '100%' }}>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Progreso anual de ingresos
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <div style={{ width: '60%', height: '60%', margin: 'auto' }}>
                      <CircularProgressbar
                        value={50}
                        text='50%'
                        styles={buildStyles({
                          pathColor: `#3ABFEC`,
                        })}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>

              {/* Progreso anual de abrazos brindados */}
              <Grid item xxl={3} xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                <Box className='graph_card' sx={{ width: '100%' }}>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Progreso anual de abrazos brindados
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <div style={{ width: '60%', height: '60%', margin: 'auto' }}>
                      <CircularProgressbar
                        value={75}
                        text='75%'
                        styles={buildStyles({
                          pathColor: `#3ABFEC`,
                        })}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>

              <Grid item xxl={3} xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                <Box className='graph_card' sx={{ width: '100%' }}>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Farmacia o vendedor con más ventas */}
                      Farmacia / vendedor con más ventas
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3, textAlign: 'center' }}>
                    <PersonIcon sx={{ fontSize: '100px', color: 'rgba(133, 193, 233, 0.8)' }} />
                    <Typography variant='h6' sx={{ fontStyle: 'italic' }}>
                      Lorem ipsum - 363 ventas
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xxl={3} xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                <Box className='graph_card' sx={{ width: '100%' }}>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Top 5 canales con más venta */}
                      Top 5 canales con más venta
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    {
                      dataPrueba.map((res) => (
                        <Box key={res.id}>
                          <Typography variant='body2' sx={{ fontStyle: 'italic', fontWeight: '600' }} gutterBottom>
                            {res.id}. {res.name} - {res.sales} ventas
                          </Typography>
                        </Box>
                      ))
                    }
                  </Box>
                </Box>
              </Grid>

              <Grid item xxl={3} xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                <Box className='graph_card' sx={{ width: '100%' }}>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Venta global del mes */}
                      Venta global del mes
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3, textAlign: 'center' }}>
                    <AttachMoneyIcon sx={{ fontSize: '60px', mb: 2.3, color: '#52BE80' }} />
                    <Typography variant='h5' sx={{ fontWeight: '500' }}>$999,999,999 MXN</Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xxl={3} xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                <Box className='graph_card' sx={{ width: '100%' }}>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Venta global de la semana */}
                      Venta global de la semana
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3, textAlign: 'center' }}>
                    <AttachMoneyIcon sx={{ fontSize: '60px', mb: 2.3, color: '#52BE80' }} />
                    <Typography variant='h5' sx={{ fontWeight: '500' }}>$999,999,999 MXN</Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xxl={6} xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Top 10 productos más vendidos */}
                      Top 10 productos más vendidos
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Top10Products />
                  </Box>
                </Box>
              </Grid>

              <Grid item xxl={6} xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Sumatoria de venta
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <SumatoriaVenta />
                  </Box>
                </Box>
              </Grid>

              <Grid item xxl={6} xl={6} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Cantidad de producto desplazado */}
                      Cantidad de producto desplazado
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph1 />
                  </Box>
                </Box>
              </Grid>

              <Grid item xxl={6} xl={6} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Suma de Venta antes de descuento */}
                      Suma de Venta antes de descuento
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph2 />
                  </Box>
                </Box>
              </Grid>

              <Grid item xxl={6} xl={6} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Venta con descuento ($) */}
                      Venta con descuento ($)
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph3 />
                  </Box>
                </Box>
              </Grid>

              <Grid item xxl={6} xl={6} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Participación de venta en piezas (cantidad) */}
                      Participación de venta en piezas (cantidad)
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph4 />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Evolución de ventas */}
                      Evolución de ventas
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <SalesPerformance />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Participación de venta */}
                      Participación de venta
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3, textAlign: 'center' }}>
                    <ParticipaciónVenta />
                  </Box>
                </Box>
              </Grid>

              {/* <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Suma de Subtotal Costo Producto
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph5 />
                  </Box>
                </Box>
              </Grid> */}

              {/* <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Suma de Utilidad
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph6 />
                  </Box>
                </Box>
              </Grid> */}

              {/* <Grid item xl={8} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Promedio de % Utilidad
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph7 />
                  </Box>
                </Box>
              </Grid> */}

              {/* <Grid item xl={4} lg={12} md={8} sm={12} xs={12} sx={{ mx: 'auto' }}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Progreso esperado
                      Progreso global anual de venta
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph8 />
                  </Box>
                </Box>
              </Grid> */}

            </Grid>
          </Grid>
        </Grid>

        {/* <Chip label="10/20/2002 - 14/23/0001" onDelete={() => {alert('Borrar')}}  sx={{background: 'red', mb: 3}} /> */}
      </Container>

      <SidebarAdmin
        businessNameDisplay={businessNameDisplay}
        setBusinessNameDisplay={setBusinessNameDisplay}
        expandedData={expandedData}
        setExpandedData={setExpandedData}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        INITIAL_DATE={INITIAL_DATE}
        dataForDisplay={dataForDisplay}
        setDataForDisplay={setDataForDisplay}
        expanded={expanded}
        setExpanded={setExpanded}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        checkedSS={checkedSS}
        setCheckedSS={setCheckedSS}
      />
    </>
  );
};

export default IndexAdmin;