import React, { useState } from 'react'

//MUI
import { FormGroup, FormControlLabel, Checkbox, InputAdornment, IconButton, OutlinedInput, InputLabel, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SSData = [
    {
        id: 1,
        name: 'SUC. ROMA',
    },
    {
        id: 2,
        name: 'SUC. LEON',
    },
    {
        id: 3,
        name: 'SUC. TUXTLA',
    },
    {
        id: 4,
        name: 'SUC. VALLE DE ARAGÓN',
    },
    {
        id: 5,
        name: 'SUC. OAXACA',
    },
    {
        id: 6,
        name: 'SUC. MONTERREY',
    },
    {
        id: 7,
        name: 'SUC. TLALPAN',
    },
    {
        id: 8,
        name: 'SUC. IZTAPALAPA',
    },
    {
        id: 9,
        name: 'SUC. QUERETARO',
    },
    {
        id: 10,
        name: 'SUC. HERMOSILLO',
    },
    {
        id: 11,
        name: 'SUC. AMZ',
    },
]

const StoreSellerFilter = ({checkedSS, setCheckedSS}) => {

    const handleCheckboxChange = (data) => {
        const isChecked = checkedSS.some(checkedCheckbox => checkedCheckbox.id === data.id)
        if (isChecked) {
            setCheckedSS(
                checkedSS.filter(
                    (checkedCheckbox) => checkedCheckbox.id !== data.id
                )
            );
        } else {
            setCheckedSS(checkedSS.concat(data));
        }
    };

    const [search, setSearch] = useState('')

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const filtered = !search
        ? SSData
        : SSData.filter((res) =>
            res.name.toLowerCase().includes(search.toLowerCase())
        );

    const handleResetSearch = () => {
        setSearch('')
    }

    return (
        <>
            <FormControl fullWidth variant="outlined" sx={{ mb: 3 }} size='small'>
                <InputLabel htmlFor="prod_search">Buscar farmacia/vendedor</InputLabel>
                <OutlinedInput
                    id="prod_search"
                    value={search}
                    onChange={handleSearch}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleResetSearch}
                                edge="end"
                            >
                                {search.length > 1 ? <CloseIcon /> : null}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Buscar farmacia/vendedor"
                />
            </FormControl>
            <FormGroup>
                {
                    filtered.map((res) => (
                        <FormControlLabel key={res.id}
                            control={
                                <Checkbox
                                    value={res.id}
                                    checked={checkedSS.some(checkedCheckbox => checkedCheckbox.id === res.id)}
                                    onChange={() => handleCheckboxChange(res)}
                                />
                            }
                            label={res.name}
                        />
                    ))
                }
            </FormGroup>
        </>
    )
}

export default StoreSellerFilter