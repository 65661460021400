import { basicMsg } from "../helpers/messagesHelper";
import { protectedReq, request } from "../helpers/requestHelper";

import { startOfDay, endOfDay, subWeeks } from 'date-fns';

import { DATASET_GRAPH_FOUR, DATASET_GRAPH_ONE, DATASET_GRAPH_TWO } from "../constants/graph_constants";

// productos / categorías / razones sociales / ventas
export const salesData = async (setData) => {
    try {
        const today = new Date();
        const resp = await request('sales/data', {
            filterDate: [
                startOfDay(new Date('05-20-2024')),
                // subWeeks(startOfDay(today), 1),
                endOfDay(today)
            ]
        }, 'POST');
        const body = await resp.json();
        if (body.status) {
            const {
                graphs: { labels, amount, quantity },
                ...resp
            } = body.resp
            setData(e => ({
                ...e, firstLoading: false, ...resp,
                graphs: {
                    ...e.graphs,
                    one: {
                        ...e.graphs.one,
                        labels,
                        datasets: [{
                            ...DATASET_GRAPH_ONE,
                            data: quantity
                        }]
                    },
                    two: {
                        ...e.graphs.two,
                        labels,
                        datasets: [{
                            ...DATASET_GRAPH_TWO,
                            data: amount
                        }]
                    },
                    three: {
                        ...e.graphs.three,
                        labels,
                        datasets: [{
                            ...DATASET_GRAPH_FOUR,
                            data: amount
                        }]
                    },
                    four: {
                        ...e.graphs.four,
                        labels,
                        datasets: [{
                            ...DATASET_GRAPH_FOUR,
                            data: quantity
                        }]
                    }
                },
                backup: {
                    ...e.backup, ...resp,
                    graphs: {
                        ...e.graphs,
                        one: {
                            ...e.graphs.one,
                            labels,
                            datasets: [{
                                ...DATASET_GRAPH_ONE,
                                data: quantity
                            }]
                        },
                        two: {
                            ...e.graphs.two,
                            labels,
                            datasets: [{
                                ...DATASET_GRAPH_TWO,
                                data: amount
                            }]
                        },
                        three: {
                            ...e.graphs.three,
                            labels,
                            datasets: [{
                                ...DATASET_GRAPH_FOUR,
                                data: amount
                            }]
                        },
                        four: {
                            ...e.graphs.four,
                            labels,
                            datasets: [{
                                ...DATASET_GRAPH_FOUR,
                                data: quantity
                            }]
                        }
                    },
                }
            }));
        } else {
            setData(e => ({
                ...e, firstLoading: false
            }));
            if (['ax-err', 'server-err'].includes(body.msg))
                basicMsg('error', 'Dashboard',
                    'Incidente al recuperar información de ventas');
        }
    } catch (err) {
        setData(e => ({
            ...e, firstLoading: false
        }));
        basicMsg('error', 'Dashboard',
            'No se pudo mostrar información de ventas');
    };
};

// filtro
export const salesFilter = async (businessName, filterDate, products, category, setData) => {
    try {
        const resp = await protectedReq('sales/filter', {
            businessName: businessName.map(({ // eliminar campo 'checked'
                business_id, business_name
            }) => ({ business_id, business_name })),
            filterDate: [startOfDay(filterDate[0]), endOfDay(filterDate[1])],
            products, category
        }, 'POST');
        const body = await resp.json();
        if (body.status) {
            const { graphs: { labels, amount, quantity } } = body.resp
            setData(e => ({
                ...e,
                graphs: {
                    ...e.graphs,
                    one: {
                        ...e.graphs.one,
                        labels,
                        datasets: [{
                            ...DATASET_GRAPH_ONE,
                            data: quantity
                        }]
                    },
                    two: {
                        ...e.graphs.one,
                        labels,
                        datasets: [{
                            ...DATASET_GRAPH_TWO,
                            data: amount
                        }]
                    },
                    three: {
                        ...e.graphs.three,
                        labels,
                        datasets: [{
                            ...DATASET_GRAPH_FOUR,
                            data: amount
                        }]
                    },
                    four: {
                        ...e.graphs.four,
                        labels,
                        datasets: [{
                            ...DATASET_GRAPH_FOUR,
                            data: quantity
                        }]
                    }
                }
            }));
        } else {
            if (['ax-err', 'server-err'].includes(body.msg))
                basicMsg('error', 'Dashboard',
                    'Incidente al aplicar filtro de venta');
            else if (body.msg === 'no-data')
                setData(e => ({
                    ...e,
                    graphs: {
                        ...e.graphs,
                        one: {
                            ...e.graphs.one,
                            labels: [],
                            datasets: [{
                                ...DATASET_GRAPH_ONE,
                                data: []
                            }]
                        },
                        two: {
                            ...e.graphs.one,
                            labels: [],
                            datasets: [{
                                ...DATASET_GRAPH_TWO,
                                data: []
                            }]
                        },
                        three: {
                            ...e.graphs.three,
                            labels: [],
                            datasets: [{
                                ...DATASET_GRAPH_FOUR,
                                data: []
                            }]
                        },
                        four: {
                            ...e.graphs.four,
                            labels: [],
                            datasets: [{
                                ...DATASET_GRAPH_FOUR,
                                data: []
                            }]
                        }
                    }
                }));
        }
    } catch (err) {
        basicMsg('error', 'Dashboard',
            'No se pudo aplicar el filtro');
    };
};